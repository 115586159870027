<template>
  <div class="modules view">
    <image-header/>
    <div v-if="loading" class="text-center my-5 py-5">
      <img src="/img/loading.svg" alt="loading">
    </div>
    <div v-else class="container">
      <div class="row my-5">
        <div v-for="module in modules" class="col-md-3 mb-3">
          <module-card class="h-100" :module="module"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageHeader from "@/components/ImageHeader";
import {mapActions, mapMutations, mapState} from "vuex";
import ModuleCard from "@/components/ModuleCard";
import CustomDropdown from "@/components/CustomDropdown";

export default {
  name: "Modules",
  components: {CustomDropdown, ModuleCard, ImageHeader},
  data() {
    return {
      loading: true
    }
  },
  computed: {
    ...mapState({
      region: state => state.Api.Regions.current,
      modules: state => state.Api.Modules.all,
    }),
  },
  created() {
    this.fetchModules();
  },
  methods: {
    ...mapMutations({
      clearModules: "Api/Modules/reset"
    }),
    ...mapActions({
      getModules: "Api/Modules/all"
    }),
    fetchModules() {
      this.loading = true
      let options = {
        region_id: this.region.id,
        sort: "default",
        order: "asc",
        limit: Number.MAX_SAFE_INTEGER
      }

      this.clearModules();
      this.getModules(options)
          .catch(this.apiErrorHandler)
          .finally(() => this.loading = false)
    }
  }
}
</script>
