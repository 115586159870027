<template>
  <div id="app">
    <main-navigation :routes="mainNavigationRoutes">
      <img src="./assets/hensel_logo.svg"
           class="img-fluid mr-3"
           alt="Hensel Recycling"
      />
    </main-navigation>

    <div v-if="!region" class="text-center my-5">
      <img src="/img/loading.svg" alt="loading">
    </div>
    <router-view v-else/>

    <app-footer v-if="region">
      <h1>{{ region.public_config.footer.title }}</h1>

      <p>{{ region.public_config.footer.body }}</p>

      <router-link :to="region.public_config.footer.link" class="btn btn-primary">
        {{ region.public_config.footer.link_text }}
      </router-link>

      <hr class="border-light">

      <span>{{ $t('footer.copy_right', {year: currentYear, text: region.public_config.footer.copy_right}) }}</span>

    </app-footer>
  </div>
</template>

<script>

import ApiAppMixin from "./mixins/api-app-mixin"
import RegionAppMixin from "./mixins/region-app-mixin"

import MainNavigation from "pixelstein-vue-app-package/src/components/Navigation/MainNavigation"
import AppFooter from "@/components/AppFooter";
import {mainNavigationRoutes} from "@/router";
import {mapState} from "vuex";
import dayjs from "dayjs";


export default {
  name: "App",
  mixins: [ApiAppMixin, RegionAppMixin],
  components: {AppFooter, MainNavigation},
  computed: {
    ...mapState({
      tokenData: state => state.Api.authTokenData
    }),
    currentYear() {
      return dayjs().format('YYYY')
    },
    mainNavigationRoutes() {
      if (!this.tokenData) {
        return []
      }
      return mainNavigationRoutes
    }
  }
}

</script>

<style lang="scss">
@import "./styles/main";
</style>
