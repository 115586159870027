import {mapActions, mapMutations} from "vuex";

export default {
	created() {
		this.setApiBaseUrl(this.$config.API_BASE_URL);
		
		window.addEventListener('focus', this.checkAuthToken);
		this.checkAuthToken()
	},
	beforeDestroy() {
		window.removeEventListener('focus', this.checkAuthToken)
	},
	methods: {
		...mapActions({
			updateAuthToken: "Api/updateAuthToken",
			logout: "Api/Users/logout"
		}),
		...mapMutations({
			setApiBaseUrl: "Api/setApiBaseUrl",
		}),
		async checkAuthToken() {
			let storedToken = window.localStorage.getItem(process.env.VUE_APP_AUTH_TOKEN_STORAGE_KEY || 'VUE_APP_AUTH_TOKEN_STORAGE_KEY')
			
			try {
				await this.updateAuthToken(storedToken);
				console.log("provided token looks valid")
			} catch (e) {
				console.log("provided token is invalid")
				
				if (this.$config.PUBLIC_ACCESS) {
					return console.log("public access is enabled")
					
				}
				
				if (!this.$router.currentRoute.path.match(/(enroll)|(reset_password)|(login)|(config)/gi)) {
					console.log("open login view with success route => ", this.$route.path)
					this.$router
						.push([this.$config.LOGIN_PATH || '/login', btoa(this.$route.path)].join('/'))
						.catch(() => null)
				}
			}
		}
	}
}
