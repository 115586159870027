<template>
  <div class="card">
    <img v-if="image" :src="image" class="card-img-top" :alt="image">
    <div class="card-body">
      <h5 v-if="title" class="card-title">{{ title }}</h5>
      <p v-if="body" class="card-text">{{ body }}</p>
      <slot/>
    </div>
  </div>
</template>

<script>
export default {
  name: "GeneralCard",
  props: {
    image: {type: String, default: null},
    title: {type: String, default: null},
    body: {type: String, default: null}
  }
}
</script>

<style scoped>

</style>
