<template>
  <div class="form-image-upload" :class="{'invalid':!!invalidNote}">
    <div>
      <div class="inner-wrap">
        <div class="position-relative d-inline-block p-2">
          <div v-if="uploadProgress > 0 && uploadProgress < 100" class="progress-circle">
            {{ Math.round(uploadProgress) }}%
          </div>
          <template v-else>
            <slot/>
          </template>
        </div>

        <input type="file"
               name="file"
               class="uploadfile"
               :id="uuid"
               :form="formId"
               :accept="accept"
               :disabled="disabled"
               @change="onChange"
        />
      </div>

    </div>
    <div class="invalid-note text-danger">
      {{ invalidNote }}
    </div>
  </div>

</template>

<script>

import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(VueAxios, axios);

import {v1} from 'uuid';
import {mapActions, mapGetters, mapState} from "vuex";

export default {
  name: "FormFileUpload",
  props: {
    value: {type: String, default: null},
    label: String,
    accept: {type: String, default: '.jpg,.png,.gif'},
    sorting: {type: Number, default: 10},
    disabled: {type: Boolean, default: false},
    minWidth: Number,
    maxWidth: Number,
    minRatio: Number,
    maxRatio: Number,
  },
  // watch: {
  //   value(newValue, oldValue) {
  //     if (newValue && newValue !== oldValue) {
  //       this.fetchContent(newValue)
  //     }
  //   }
  // },
  data() {
    return {
      uuid: v1(),
      uploadProgress: 0,
      invalidNote: null,
      // filetypes: {
      // 	image: ['.png', '.jpg', '.jpeg', '.gif'],
      // 	video: ['.mp4', '.m4v', '.mpeg', '.webm']
      // },
      // previewContent: null,
    }
  },
  computed: {
    ...mapState({
      apiBaseUrl: state => state.Api.apiBaseUrl
    }),
    ...mapGetters({
      getAuthHeader: "Api/getAuthHeader"
    }),
    formId() {
      return 'form_' + this.uuid
    },
  },
  beforeMount() {
    //create form element and append it to the body
    let formNode = document.createElement('form');
    formNode.id = this.formId;
    formNode.enctype = 'multipart/form-data';

    document.body.append(formNode);
  },
  mounted() {
    if (this.value) {
      this.fetchContent(this.value)
    }
  },
  updated() {
    this.$emit('progress-change', this.uploadProgress);
    this.$emit('invalid-note-change', this.invalidNote);
  },
  beforeDestroy() {
    document.getElementById(this.formId).remove()
  },
  methods: {
    // ...mapActions({
    //   getFile: "Api/Files/get"
    // }),
    async onChange(e) {

      this.invalidNote = null

      let count = 1;
      let files = e.target.files; // puts all files into an array

      // call them as such; files[0].size will get you the file size of the 0th file
      let cancel = false;
      for (let fidx = 0; fidx < files.length; fidx++) {
        let f = files[fidx];
        let size = ((f.size / 1024) / 1024).toFixed(4)
        console.log('check', ((f.size / 1024) / 1024).toFixed(4))
        if (size > this.$config.MAX_UPLOADS_FILE_SIZE_IN_MB) {
          cancel = true
          this.invalidNote = this.$t("Die ausgewählte Datei ist zu groß. Maximale Dateigröße 10MB")
        }

        if (f.type.split('/').shift() === 'image') {
          try {

            let x = await this.checkImageSize(f)
            console.log("try end", x)
          } catch (e) {
            this.invalidNote = this.$t(e);
            cancel = true
          }


        }
      }


      if (cancel) {
        return
      }


      let formData = new FormData(e.target.form);
      formData.append('sorting', this.sorting)
      let url = [this.apiBaseUrl, 'files/add.json'].join('/');
      let headers = this.getAuthHeader;

      this.$http.post(url, formData, {headers: headers, onUploadProgress: this.onUploadProgress})
          .then(result => {
            // this.uploadResult = result.data.data
            this.$emit('input', result.data.data.id)
            this.uploadProgress = 100;
          })
          .catch(err => {
            this.invalidNote = err;
            this.uploadProgress = 0;
          })
    },
    checkImageSize(f) {
      return new Promise((resolve, reject) => {
        let img = new Image();
        let objectUrl = URL.createObjectURL(f);
        img.onload = function () {
          console.log("onload")

          if (this.width < 100 || this.height < 100) {
            console.log("will reject")

            reject("Grafiken müssen mindesten 100px hoch und breit sein.")
            return
          }
          resolve(true);
        };
        img.onerror = () => reject('Grafik fehlerhaft oder nicht unterstützt')
        img.src = objectUrl;
      })
    },
    onUploadProgress(e) {
      this.uploadProgress = (e.loaded / (e.total / 100)) * .9
    },
    // fetchContent(file_id) {
    //   this.getFile({id:file_id})
    //       .then(result => this.previewContent = result)
    //       .catch(this.apiErrorHandler)
    // }

  }
}


</script>

<style scoped>

</style>
