<template>
  <div class="dashboard view">
    <image-header :image="region.public_config.dashboard.image_header">
      {{ $t('dashboard.title') }}
    </image-header>

    <div v-if="loading" class="text-center my-5 py-5">
      <img src="/img/loading.svg" alt="loading">
    </div>
    <div v-else class="container pb-4">

      <sortable-list-empty-list-note
          v-if="[...mandatoryAssignments, ...uniqueFinishedNormalModules, ...favourites].length === 0"
          :title="$t('dashboard.empty_board.title')"
          :body="$t('dashboard.empty_board.body')"
      />

      <!-- Modules -->
      <h1 v-if="mandatoryAssignments.length > 0">{{ $t('dashboard.mandatory_modules.title') }}</h1>
      <ps-collapse v-if="mandatoryAssignments.length > 0"
                   v-for="assignment in mandatoryAssignments"
                   :key="assignment.id"
                   class="bg-light my-4">
        <template #header="{active}">
          <div class="row align-items-center px-3">
            <div class="col-2 col-md-1">
              <i :class="{active}" class="collapse-icon fas fa-chevron-down fa-2x text-primary" aria-hidden="true"></i>
            </div>
            <div class="col">
              <h2>{{ assignment.curriculum.name }}</h2>
            </div>
            <div class="col-md-3">
              {{ $t('dashboard.mandatory_modules.due', {date: toFormattedDate(assignment.complete_by)}) }}
            </div>
          </div>
        </template>
        <template #content>

          <sortable-list default-sort-key="name" :items="assignment.curriculum.modules">
            <template #header="options">
              <div class="row">
                <sortable-list-column-header sort-by="name" :options="options" class="col offset-md-1">
                  {{ $t('dashboard.mandatory_modules.list.header.name') }}
                </sortable-list-column-header>
                <div class="col">
                  {{ $t('dashboard.mandatory_modules.list.header.progress') }}
                </div>
                <div class="col-md-2"></div>
              </div>
            </template>

            <template #itemTemplate="{item, items, itemIndex}">
              <div class="row align-items-center pb-2">
                <div class="col-2 col-md-1 text-center text-primary">
                  <i v-if="item.finished_modules.length > 0 && item.finished_modules[item.finished_modules.length -1].completed"
                     class="fas fa-check" aria-hidden="true"></i>
                </div>
                <div class="col font-weight-bold">
                  {{ item.name }}
                </div>
                <div class="col text-primary">
                  {{ $t('dashboard.mandatory_modules.list.item.progress', {percent: getProgress(item)}) }}
                </div>
                <div class="col-md-2">
                  <router-link class="btn btn-primary btn-block" :to="r('/modules','view', item.id)">
                    {{
                      item.finished_modules.length > 0 && item.finished_modules[item.finished_modules.length - 1].completed
                          ? $t('dashboard.restart')
                          : $t('dashboard.start')
                    }}
                  </router-link>
                </div>
                <div class="col-12">
                  <hr v-if="itemIndex !== items.length -1" class="mb-0"/>
                </div>
              </div>
            </template>

            <template #emptyList>
              <sortable-list-empty-list-note
                  :body="$t('dashboard.mandatory_modules.list.empty.title')"
              />
            </template>

          </sortable-list>

        </template>
      </ps-collapse>

      <template v-if="uniqueFinishedNormalModules.length > 0">
        <h1>{{ $t('dashboard.completed_modules.title') }}</h1>
        <sortable-list default-sort-key="name" :items="uniqueFinishedNormalModules">
          <template #header="options">
            <div class="row px-2">
              <sortable-list-column-header
                  sort-by="name"
                  :options="options"
                  class="col-3"
              >
                {{ $t('dashboard.completed_mandatory_modules.list.header.name') }}
              </sortable-list-column-header>
              <sortable-list-column-header
                  sort-by="sudden_death"
                  :options="options"
                  class="col-2"
              >
                {{ $t('dashboard.completed_mandatory_modules.list.header.quiz_type') }}
              </sortable-list-column-header>
              <sortable-list-column-header sort-by="completed" :options="options" class="col-3 col-md-5">
                {{ $t('dashboard.completed_mandatory_modules.list.header.completed') }}
              </sortable-list-column-header>
            </div>
          </template>

          <template #itemTemplate="{item}">
            <div class="row align-items-center">
              <div class="col-3 font-weight-bold">
                {{ item.name }}
              </div>
              <div class="col-2 font-weight-bold">
                {{
                  item.sudden_death
                      ? $t('dashboard.completed_mandatory_modules.list.item.quiz_type_sudden_death')
                      : $t('dashboard.completed_mandatory_modules.list.item.quiz_type_normal')
                }}
              </div>
              <div class="col-3 col-md-5 font-weight-bold">
                {{
                  $t('dashboard.completed_mandatory_modules.list.item.completed', {dateTime: toFormattedDateTime(item.completed)})
                }}
              </div>
              <div class="col">
                <router-link class="btn btn-primary btn-block" :to="r('/modules','view', item.id)">
                  {{ $t('dashboard.completed_mandatory_modules.list.item.button') }}
                </router-link>
              </div>
            </div>
          </template>

          <template #emptyList>
            <sortable-list-empty-list-note
                :body="$t('dashboard.completed_mandatory_modules.list.empty.title')"
            />
          </template>

        </sortable-list>
      </template>

      <template v-if="favourites.length > 0">
        <h1>{{ $t('dashboard.favorite_modules') }}</h1>
        <div class="row">
          <div v-for="favourite in favourites" :key="favourite.id" class="col-sm-6 col-lg-3 mb-3">
            <module-card :module="favourite.module"/>
          </div>
        </div>
      </template>
    </div>

  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";

import DateFormats from "pixelstein-vue-app-package/src/mixins/date-formats"

import ModuleCard from "@/components/ModuleCard";
import ImageHeader from "@/components/ImageHeader";
import ModuleBar from "@/components/ModuleBar";

import PsCollapse from "pixelstein-vue-app-package/src/components/Accordion/PsCollapse";
import SortableList from "pixelstein-vue-app-package/src/components/SortableList/SortableList";
import SortableListColumnHeader from "pixelstein-vue-app-package/src/components/SortableList/SortableListColumnHeader";
import SortableListEmptyListNote
  from "pixelstein-vue-app-package/src/components/SortableList/SortableListEmptyListNote";

export default {
  name: "Dashboard",
  mixins: [DateFormats],
  components: {
    ModuleBar,
    ImageHeader,
    ModuleCard,
    PsCollapse,
    SortableList,
    SortableListColumnHeader,
    SortableListEmptyListNote,
  },
  data() {
    return {
      finishedModules: [],
      loading: true
    }
  },
  computed: {
    ...mapState({
      region: state => state.Api.Regions.current,
      modules: state => state.Api.Modules.all,
      assignments: state => state.Api.Assignments.all,
      favourites: state => state.Api.Favourites.all
    }),
    mandatoryAssignments() {
      return this.assignments
          .filter(el => el.required)
          .filter((element, index, array) => index === array.findIndex(el => el.curriculum.id === element.curriculum.id))
    },
    mandatoryModules() {
      return this.mandatoryAssignments
          .map(el => el.curriculum.modules)
          .reduce((previousValue, currentValue) => previousValue.concat(currentValue), [])
          .filter((element, index, array) => index === array.findIndex(el => el.id === element.id))
    },
    uniqueFinishedModules() {
      return this.finishedModules
          .slice()
          .filter(el => !!el.completed)
          .sort((a, b) => b.completed.localeCompare(a.completed))
          .map(el => Object.assign(el, el.module))
          .filter((element, index, array) => index === array.findIndex(el => el.id === element.id))
    },
    uniqueFinishedMandatoryModules() {
      return this.uniqueFinishedModules
          .filter(el => !!this.mandatoryModules.find(m => m.id === el.id))
    },
    uniqueFinishedNormalModules() {
      return this.uniqueFinishedModules
          .filter(el => !this.mandatoryModules.find(m => m.id === el.id))
    }
  },
  created() {
    let promises = [];
    promises.push(this.getFinishedModules({id: 'self'})
        .then(result => this.finishedModules = result))

    this.clearAssignments();
    promises.push(this.getAssignments({user_id: 'self', region_id: this.region.id, limit: Number.MAX_SAFE_INTEGER}))

    promises.push(this.getFavourites({user_id: 'self', region_id: this.region.id, limit: Number.MAX_SAFE_INTEGER}))

    Promise.all(promises)
        .catch(this.apiErrorHandler)
        .finally(() => this.loading = false)
  },
  methods: {
    ...mapMutations({
      clearAssignments: "Api/Assignments/reset",
      clearFavourites: "Api/Favourites/reset"
    }),
    ...mapActions({
      getFinishedModules: "Api/Users/finishedModules",
      getAssignments: "Api/Assignments/all",
      getFavourites: "Api/Favourites/all"
    }),
    getProgress(item) {
      if ((item.module_steps_count || 0) < 1) {
        return 'n/a'
      } else if (item.finished_modules.length > 0) {
        const lastFinishedModule = item.finished_modules[item.finished_modules.length - 1]
        const lastCompletedStep = (lastFinishedModule.last_module_step_completed || 0) + 1

        if (lastFinishedModule.completed) {
          return '100'
        }

        return `${lastCompletedStep / item.module_steps_count * 100}`
      } else {
        return '0'
      }
    }
  }
}
</script>

