import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'

// App Package
import 'pixelstein-vue-app-package/src/update-checker'
import RuntimeConfigPlugin from "@pixelstein/runtime-config-plugin/dist/RuntimeConfigPlugin"

// Global mixins
import ApiHelper from 'pixelstein-vue-app-package/src/mixins/api-helper'

Vue.mixin(ApiHelper)

import RouterUtil from '@/mixins/router-util'

Vue.mixin(RouterUtil)

Vue.config.productionTip = false;

(async function () {
	const configPlugin = new RuntimeConfigPlugin("/", process.env);
	
	await configPlugin.load(process.env.NODE_ENV)
	
	Vue.use(configPlugin)
	
	new Vue({
        router,
        store,
        i18n,
        render: h => h(App)
    }).$mount('#app')
})()
