<template>
  <div class="media-player row">
    <div ref="cf" class="current-file text-center">
      <button type="button" class="toggle-fullscreen" @click="toggleFullscreen">
        <i class="fas fa-expand-alt" aria-hidden="true"></i>
      </button>
      <video v-if="currentFileType === 'video'"
             ref="video_player"
             :src="$config.API_BASE_URL+'/'+currentFile.file"
             :poster="$config.API_BASE_URL+'/'+currentFile.thumbnail"
             controls
             preload="auto"
             class="w-100"
             @play="playing = true"
             @pause="playing = false"
             @timeupdate="currentTime = $event.target.currentTime"
             @loadedmetadata="currentDuration = $event.target.duration"
      >
      </video>
      <img v-else-if="currentFileType === 'image'"
           :src="$config.API_BASE_URL+'/'+currentFile.file"
           class="img-fluid"
           :alt="currentFile.file"
      >
      <div v-if="!playing" class="toolbar">
        <button v-if="progress > 90" type="button" class="btn" @click="restart">
          <i class="fas fa-undo-alt fa-2x" aria-hidden="true"></i><br>
          {{ $t('media_player.restart') }}
        </button>
        <button v-if="progress > 90 && !nextFile" type="button" :disabled="nextStepLock" class="btn" @click="$emit('nextStep')"> <!-- emit to nextStep -->
          <i class="fas fa-step-forward fa-2x" aria-hidden="true"></i><br>
          <template v-if="nextStepLock">{{ $t('module_content.countdown', { minutes: countdown }) }}</template>
          <template v-else>{{ $t('module_content.next') }}</template>
        </button>
        <button v-if="progress < 99" type="button" class="btn" @click="$refs.video_player.play()">
          <i class="fas fa-play fa-2x" aria-hidden="true"></i><br>
          {{ $t('media_player.start') }}
        </button>
        <button v-if="progress > 90 && nextFile" type="button" class="btn" @click="startNextVideo">
          <i class="fas fa-step-forward fa-2x" aria-hidden="true"></i><br>
          {{ $t('media_player.next') }}
        </button>
      </div>
    </div>
    <div class="file-preview">

      <vue-slick-carousel ref="playlist_slick" :slidesToShow="4" :infinite="false" arrows>
        <div v-for="file in files"
             :key="file.id"
             class="preview p-1"
             :class="{active: currentFile.id === file.id}"
             @click="currentFile = file"

        >
          <img v-if="file.thumbnail"
               :src="$config.API_BASE_URL+'/'+file.thumbnail"
               class="img-fluid"
               :alt="file.thumbnail"
          >
          <i v-else class="fas fa-video" aria-hidden="true"></i>
        </div>
        <template #prevArrow="arrowOption">
          <i class="fas fa-chevron-left" aria-hidden="true"></i>
        </template>
        <template #nextArrow="arrowOption">
          <i class="fas fa-chevron-right" aria-hidden="true"></i>
        </template>
      </vue-slick-carousel>

    </div>
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  name: "MediaPlayer",
  components: {VueSlickCarousel},
  props: {
    files: {type: Array, default: () => []},
    startFileIdx: {type: Number, default: 0},
    nextStepLock: {type: Boolean, default: false},
    countdown: ''
  },
  watch: {
    files(nv) {
      this.currentFile = nv[this.startFileIdx]
      this.$refs.playlist_slick.goTo(this.startFileIdx)
    },
    startFileIdx(nv) {
      this.currentFile = this.files[nv]
      this.$refs.playlist_slick.goTo(nv)
    }
  },
  data() {
    return {
      currentFile: null,
      playing: false,
      currentTime: 0,
      currentDuration: 0,
    }
  },
  computed: {
    currentFileType() {
      if (!this.currentFile) {
        this.currentFile = this.files[0]
      }

      return this.currentFile.type.split('/')[0]
    },
    progress() {
      return Math.round(this.currentTime / this.currentDuration * 100)
    },
    currentFileIdx() {
      return this.files.findIndex(file => file.id === this.currentFile.id)
    },
    nextFile() {
      if (this.currentFileIdx < 0) {
        return null
      }
      return this.files[this.currentFileIdx + 1]
    }
  },
  mounted() {
    this.currentFile = this.files[this.startFileIdx]
    this.$refs.playlist_slick.goTo(this.startFileIdx)
  },
  methods: {
    toggleFullscreen() {
      if (!document.fullscreenElement) {
        this.$refs.cf.requestFullscreen().catch((err) => {
          alert(`Error attempting to enable fullscreen mode: ${err.message} (${err.name})`);
        });
      } else {
        document.exitFullscreen();
      }
    },
    restart() {
      this.$refs.video_player.currentTime = 0;
      this.$refs.video_player.play()
      this.$refs.playlist_slick.goTo(this.currentFileIdx)
    },
    startNextVideo() {
      this.currentFile = this.nextFile;
      this.$nextTick(this.restart)
    }
  }
}
</script>

<style scoped>

</style>
