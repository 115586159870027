<template>
  <form class="login container" @submit.prevent="tryLogin">
    <div class="row justify-content-center my-5 ">
      <div class="col-md-6 mt-5">
        <form-input :label="$t('login.username')"
                    type="text"
                    name="username"
                    :invalid-note="$t('login.invalid_email')"
                    v-model="username"
        />

        <form-input :label="$t('login.password')"
                    type="password"
                    v-model="password"
        />

        <div class="text-right">
          <button class="btn btn-primary">{{ $t('login.submit') }}</button>
        </div>

        <hr class="my-5">

        <button type="button"
                class="btn btn-secondary btn-block btn-lg"
                @click="tryOAuthSignUp"
        >
          {{ $t('login.oAuth.use_microsoft') }}<i class="ml-3 fab fa-windows"></i>
        </button>

      </div>
    </div>
  </form>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import LoginOptions from 'pixelstein-vue-app-package/src/mixins/login-options'
import FormInput from 'pixelstein-vue-app-package/src/components/Form/FormInput'
import ApiAppMixin from '@/mixins/api-app-mixin'

export default {
  name: 'Login',
  components: { FormInput },
  mixins: [LoginOptions, ApiAppMixin],
  props: {
    successRedirect: {
      type: String,
      default: () => btoa('/'),
    },
  },
  data () {
    return {
      username: '',
      password: '',
    }
  },
  computed: {
    ...mapState({
      authTokenData: state => state.Api.authTokenData,
      region: state => state.Api.Regions.current,
    }),
  },
  methods: {
    ...mapActions({
      login: 'Api/Users/login',
      logout: 'Api/Users/logout',
      getUser: 'Api/Users/get',
      authGet: 'Api/Users/authGet',
      authPost: 'Api/Users/authPost',
    }),
    async loginSuccessful (data) {
      await this.updateAuthToken(data.token)

      if (!this.$config.ALLOWED_USER_ROLES.find(role => this.authTokenData.aud[0] === role.toLowerCase())) {
        alert(this.$t('login.role_not_allowed', { roles: this.$config.ALLOWED_USER_ROLES.join() }))
        return this.logout({})
      }

      await this.$router.push(atob(this.successRedirect))
    },
    async tryLogin () {
      if (
          window.Notification
          && typeof window.Notification.requestPermission === 'function'
      ) {
        await window.Notification.requestPermission(() => null) // Firebase WebPush Permission request
      }

      try {
        const data = await this.login({
          username: this.username,
          password: this.password,
          identifier: this.getDeviceUuidOption(),
          platform: this.getDevicePlatformOption(),
          app_id: this.getBundleIdOption(),
        })

        await this.loginSuccessful(data)
      } catch (e) {
        this.apiErrorHandler(e)
      }
    },
    async onOAuthResult (params) {
      let newLocation = new URL(window.location)
      newLocation.search = ''

      history.replaceState(null, null, newLocation.toString())

      try {
        const data = await this.authPost({
          code: params.get('code'),
          state: params.get('state'),
          identifier: this.getDeviceUuidOption(),
          platform: this.getDevicePlatformOption(),
          app_id: this.getBundleIdOption(),
          region_id: this.region.id,
        })

        if (data.token) {
          this.loginSuccessful()
        }
      } catch (e) {
        this.apiErrorHandler(e)
      }
    },
    async tryOAuthSignUp () {
      try {
        const data = await this.authGet({})

        if (data.token) {
          this.loginSuccessful()
        } else if (data.authorization_url) {
          location = data.authorization_url
        }
      } catch (e) {
        this.apiErrorHandler(e)
      }
    },
  },
  created () {
    const params = new URLSearchParams(location.search)

    if (params.has('code') && params.has('state')) {
      this.onOAuthResult(params)
    }
  },
}
</script>
