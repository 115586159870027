<template>
  <footer class="app-footer">
    <div class="container text-center">
      <slot/>
    </div>
  </footer>
</template>

<script>
export default {
  name: "AppFooter",
}
</script>

<style scoped>

</style>
