<template>
  <div class="module-bar row">
    <div class="col-md-3">
      {{ module.name }}
    </div>
    <div class="col-md-3">
      progress
    </div>
    <div class="col-md-4">
      fällig am
    </div>
    <div class="col-md-2">
      <button type="button" class="btn btn-primary">
        {{ $t('module_bar.start') }}
      </button>
    </div>
    <div class="col-12">
      <hr>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModuleBar",
  props: {
    module: {type: Object, default: () => ({})}
  }
}
</script>

<style scoped>

</style>
