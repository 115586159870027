<template>
  <div class="module-complete view bg-secondary">
    <div v-if="loading" class="text-center my-5 py-5">
      <img src="/img/loading.svg" alt="loading">
    </div>
    <div v-else class="container py-1">
      <h1 class="text-white pb-5">{{ module.name }}</h1>

      <template v-if="questions.length === 0">
        <div class="text-center">
          <h2 class="text-white pt-5">{{ $t('module_complete.title_no_questions') }}</h2>

          <router-link class="btn btn-primary px-5 mb-5" :to="r('/modules', 'view', module_id)">
            {{ $t('module_complete.button_no_errors') }}
          </router-link>
        </div>
      </template>
      <template v-else-if="wrongAnsweredQuestions.length === 0">
        <div class="text-center">
          <h2 class="text-white pt-5">{{ $t('module_complete.title_no_errors') }}</h2>

          <router-link class="btn btn-primary px-5 mb-5" :to="r('/modules', 'view', module_id)">
            {{ $t('module_complete.button_no_errors') }}
          </router-link>
        </div>
      </template>
      <template v-else-if="mode === 'failed'">
        <div class="text-center">

          <h2 class="text-white py-5">
            {{ $t('module_complete.failed_title') }}
          </h2>
          <router-link class="btn btn-primary px-5 mb-5" :to="r('/modules', 'view', module_id)">
            {{ $t('module_complete.button_no_errors') }}
          </router-link>
        </div>
      </template>
      <template v-else>
        <div class="text-center">

          <h2 class="text-white py-5">{{
              $t('module_complete.title', {
                correctCount: questions.length - wrongAnsweredQuestions.length,
                allCount: questions.length
              })
            }}</h2>


          <router-link class="btn btn-primary px-5 mb-5" :to="r('/modules', 'view', module_id)">
            {{ $t('module_complete.button_no_errors') }}
          </router-link>
        </div>

      </template>
    </div>
  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";
import QuizContent from "@/components/QuizContent";

export default {
  name: "ModuleComplete",
  components: {QuizContent},
  props: {
    module_id: {type: String, default: null},
    mode: {
      type: String,
      default: 'finished',
      validator(value) {
        return !!['finished', 'failed'].find(v => v === value)
      }
    }
  },
  watch: {
    module_id(nv) {
      if (!this.module || this.module.id !== nv) {
        this.fetchModule()
      }
    },
  },
  data() {
    return {
      answeredQuestions: [],
      showQuestions: false,
      loading: true
    }
  },
  computed: {
    ...mapState({
      module: state => state.Api.Modules.current,
      questions: state => state.Api.Questions.all,
    }),
    questionsWithAnswer() {
      return this.questions.map(question => {
        console.log("question", question);
        let answeredQuestion = this.answeredQuestions.find(el => el.question_id === question.id)
        console.log("answeredQuestion", this.answeredQuestions, answeredQuestion);
        let myAnswerIds = answeredQuestion.answers.map(el => el[el.length - 1])
        console.log("myAnswerIds", myAnswerIds);

        question.answers.map(answer => {
          answer.isMyAnswer = !!myAnswerIds.find(aid => aid === answer.id);
          return answer
        })

        return question
      });
    },
    wrongAnsweredQuestions() {
      return this.questionsWithAnswer.filter(question => !!question.answers.find(answer => answer.isMyAnswer && !answer.correct))
    }
  },
  created() {
    if (!this.module || this.module.id !== this.module_id) {
      this.fetchModule()
    } else {
      this.collectQuestions().catch(this.apiErrorHandler)
    }

  },
  methods: {
    ...mapMutations({
      setModule: "Api/Modules/setCurrent",
      clearAnswers: "Api/Answers/reset",
      clearQuestions: "Api/Questions/reset"
    }),
    ...mapActions({
      getModule: "Api/Modules/get",
      getFinishedModules: "Api/Users/finishedModules",
      getQuestion: "Api/Questions/get",
    }),
    fetchModule() {
      this.setModule({item: null});
      this.getModule({id: this.module_id})
          .then(this.collectQuestions)
          .catch(this.apiErrorHandler)

    },
    async collectQuestions() {
      // try {
      const finishedModules = await this.getFinishedModules({id: "self", module_id: this.module.id})
      const finishedModule = finishedModules[finishedModules.length - 1];

      this.answeredQuestions = finishedModule.answered_questions
      console.log("collectQuestions answeredQuestion", this.answeredQuestions, finishedModule);

      this.clearQuestions();
      this.clearAnswers();
      let questions = this.answeredQuestions.map(answeredQuestion => this.getQuestion({id: answeredQuestion.question_id}))

      console.log(questions)

      await Promise.all(questions)
      this.loading = false;
      this.$forceUpdate()

      // } catch (error) {
      //   this.apiErrorHandler(error)
      // }
    },
    onDone() {
      this.$router.replace(this.r('/modules', 'view', this.module_id)).catch(() => null)
    }
  }
}
</script>

<style scoped>

</style>
