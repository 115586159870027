<template>
  <div class="custom-dropdown btn-group" @click.stop>
    <button type="button"
            class="btn p-0 "
            @click.prevent="show = !show"
    >
      <span class="h1 text-white"><slot/> <i class="fas fa-chevron-down"></i></span>
    </button>
    <div class="dropdown-menu" :class="{show}" @click="show = false">
      <slot name="item" v-for="item in items" :item="item"/>
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomDropdown",
  props: {
    items: {type: Array, default: () => []}
  },
  data() {
    return {
      show: false
    }
  },
  mounted() {
    document.querySelector('html').addEventListener('click', () => this.show = false)
  }
}
</script>

<style scoped>

</style>
