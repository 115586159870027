<template>
  <div class="module-detail view">
    <div v-if="!module" class="text-center my-5 py-5">
      <img src="/img/loading.svg" alt="loading">
    </div>
    <template v-else>

      <image-header>
        <div class="text-white mb-0">{{ module.name }}</div>
      </image-header>
      <div class="container">
        <div class="row">
          <div class="col-md-8 col-lg-7">
            <h1>{{ $t('module_detail.contents') }}</h1>
            <div v-html="module.body" style="margin-bottom: 4.6875rem"></div>
          </div>
          <div class="col-md-4 offset-lg-1">
            <h1>{{ $t('module_detail.targets') }}</h1>
            <div v-html="module.targets" style="margin-bottom: 4.6875rem"></div>
          </div>
        </div>
      </div>

      <div class="bg-secondary text-white">
        <div class="container">
          <div class="row pt-5 pb-4">
            <div class="col-md-10 offset-md-1 col-lg-9">
              <module-content-preview
                  :contents="moduleStepPreview"
                  @click="openModuleStep"
              />
            </div>
            <div v-if="firstModuleStep" class="col-md-6 offset-md-3 mt-4 mb-5">
              <router-link :to="r('/modules', 'view', module_id, firstModuleStep.id, firstModuleStepContent.id)"
                           class="btn btn-primary btn-block">
                {{ $t('module_detail.start') }}
              </router-link>
            </div>
          </div>
        </div>
      </div>

      <div v-if="filteredModules.length > 0" class="container">
        <h1>{{ $t('module_detail.recommended_modules') }}</h1>
        <div class="row my-5">
          <div v-for="module in filteredModules" class="col-md-3">
            <module-card class="h-100" :module="module"/>
          </div>
        </div>
      </div>
      <hr v-else class="m-0 border-white">
    </template>

  </div>
</template>

<script>
import ImageHeader from "@/components/ImageHeader";
import {mapActions, mapMutations, mapState} from "vuex";
import ModuleCard from "@/components/ModuleCard";
import CustomDropdown from "@/components/CustomDropdown";
import ModuleContentPreview from "@/components/ModuleContentPreview";

export default {
  name: "ModuleDetail",
  components: {ModuleContentPreview, ModuleCard, ImageHeader},
  props: {
    module_id: {type: String, default: null}
  },
  watch: {
    module_id() {
      this.fetchModule()
    }
  },
  data() {
    return {
      steps: []
    }
  },
  computed: {
    ...mapState({
      region: state => state.Api.Regions.current,
      module: state => state.Api.Modules.current,
      modules: state => state.Api.Modules.all,
    }),
    filteredModules() {
      return this.modules.filter(m => m.id !== this.module_id)
    },
    moduleStepPreview() {
      return this.steps
          .sort((a, b) => a.sorting - b.sorting)
    },
    firstModuleStep() {
      if (this.steps.length === 0) {
        return null
      }

      return this.steps[0]
    },
    firstModuleStepContent() {
      if (!this.firstModuleStep) {
        return null
      }

      return this.firstModuleStep.step_contents[0]
    }
  },
  created() {
    this.fetchModule();
  },
  methods: {
    ...mapMutations({
      setModule: "Api/Modules/setCurrent",
      clearModules: "Api/Modules/reset"
    }),
    ...mapActions({
      getModule: "Api/Modules/get",
      getModules: "Api/Modules/all",
      getModuleStep: "Api/ModuleSteps/get",
    }),
    fetchModule() {
      this.setModule({item: null});
      this.clearModules();

      this.getModule({id: this.module_id})
          .then(module => {
            let promises = [];

            module.module_steps.forEach(step => {
              promises.push(this.getModuleStep({id: step.id})
                  .then(s => this.steps.push(s)))
            })

            return Promise.all(promises)
          })
          .catch(this.apiErrorHandler)
    },
    openModuleStep({step, stepContent, fileIdx}) {
      this.$router.push(this.r('/modules', 'view', this.module_id, step, stepContent, fileIdx))
    }
  }
}
</script>
