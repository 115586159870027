import Vue from 'vue'
import VueRouter from 'vue-router'
import Modules from "@/views/Modules";
import Dashboard from "@/views/Dashboard";
import Home from "@/views/Home";
import Login from "@/views/Login";
import ModuleDetail from "@/views/ModuleDetail";
import Profile from "@/views/Profile";
import ModuleContent from "@/views/ModuleContent";
import ModuleComplete from "@/views/ModuleComplete";
import Enroll from "@/views/Enroll";

Vue.use(VueRouter)

export const mainNavigationRoutes = [
	{
		path: '/modules',
		name: 'Modules',
		component: Modules
	},
	{
		path: '/dashboard',
		name: 'Dashboard',
		component: Dashboard
	},
	{
		path: '/contact',
		name: 'Support',
		beforeEnter: (to, from, next) => {
			next(false)
			window.location = 'mailto:karriere@hensel-recycling.com'
		}
	},
	{
		path: '/profile',
		name: 'Profile',
		component: Profile,
		meta: {
			icon: '<i class="fas fa-user-circle" style="font-size: 1.5em"></i>'
		}
	},
]

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home
	},
	{
		path: '/modules/view/:module_id',
		name: 'Modules',
		component: ModuleDetail,
		props: true
	},
	{
		path: '/modules/view/:module_id/start',
		name: 'Modules',
		component: ModuleDetail,
		props: true
	},
	{
		path: '/modules/view/:module_id/:step_id',
		name: 'Modules Content',
		component: ModuleContent,
		props: true
	},
	{
		path: '/modules/view/:module_id/:step_id/:step_content_id',
		name: 'Modules Content',
		component: ModuleContent,
		props: true
	},
	{
		path: '/modules/view/:module_id/:step_id/:step_content_id/:file_idx',
		name: 'Modules Content',
		component: ModuleContent,
		props: (route) => {
			let file_idx = parseInt(route.params.file_idx, 10)
			if (Number.isNaN(file_idx)) {
				file_idx = 0
			}
			return {...route.params, file_idx}
			
		}
	},
	{
		path: '/modules/finished/:module_id',
		name: 'Modules Finished',
		component: ModuleComplete,
		props: true
	},
	{
		path: '/modules/:mode/:module_id',
		name: 'Modules Failed',
		component: ModuleComplete,
		props: true
	},
	{
		path: '/login',
		name: 'Login',
		component: Login
	},
	{
		path: '/login/:successRedirect',
		name: 'Login',
		component: Login,
		props: true
	},
	{
		path: '/enroll/:email/:enrollmentToken',
		name: 'Enrollment',
		component: Enroll,
		props: true
	},
	{
		path: '*',
		redirect: "/"
	}
]

const router = new VueRouter({
	mode: 'hash',
	base: process.env.BASE_URL,
	routes: [...routes, ...mainNavigationRoutes]
})

export default router
