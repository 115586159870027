<template>
  <div class="profile view">
    <image-header :image="region.public_config.profile.image_header">
      <span class="text-white mb-0">{{ $t('profile.title') }}</span>
    </image-header>
    <div v-if="!user" class="text-center my-5 py-5">
      <img src="/img/loading.svg" alt="loading">
    </div>
    <div v-else class="container py-5">
      <div class="row">
        <div class="col-md-3 col-lg-2">
          <form-image-upload v-model="file" @input="onSubmit">
            <div class="text-center">
              <img v-if="user.files.length > 0"
                   :src="[$config.API_BASE_URL, user.files[0].thumbnail].join('/')"
                   class="img-fluid rounded-circle"/>
              <i v-else class="fas fa-user-circle fa-6x"></i>
              <button type="button" class="btn btn-primary btn-block mt-4">{{ $t('profile.change_image') }}</button>
            </div>
          </form-image-upload>
        </div>
        <div class="col-md-6 offset-lg-1 mb-5">
          <div class="row">
            <div class="col-sm-6">
              <form-input :label="$t('profile.first_name')" :value="user.first_name" disabled/>
            </div>
            <div class="col-sm-6">
              <form-input :label="$t('profile.last_name')" :value="user.last_name" disabled/>
            </div>
            <div class="col-12">
              <label>{{ $t('profile.groups') }}</label>
              <ul>
                <li v-for="group in user.groups" :key="group.id">{{ group.name }}</li>
              </ul>
              <!--              <form-input v-for="group in user.groups" :key="group.id" :value="group.name" disabled/>-->
            </div>
            <div class="col-sm-6">
              <form-input :label="$t('profile.email')" :value="user.email" disabled/>
            </div>
            <div class="col-sm-6">
              <form-input :label="$t('profile.phone')" :value="user.phone" disabled/>
            </div>
            <div class="col-12">
              <p v-html="$t('profile.info')"></p>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-lg-2 offset-lg-1">
          <button class="btn btn-danger btn-block" @click="tryLogout">{{ $t('profile.logout') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageHeader from "@/components/ImageHeader";
import FormInput from "pixelstein-vue-app-package/src/components/Form/FormInput";
import FormImageUpload from "@/components/FormImageUpload";
import {mapActions, mapState} from "vuex";

export default {
  name: "Profile",
  components: {ImageHeader, FormInput, FormImageUpload},
  data() {
    return {
      file: null
    }
  },
  computed: {
    ...mapState({
      region: state => state.Api.Regions.current,
      user: state => state.Api.Users.current
    })
  },
  created() {
    this.getUser({id: 'self'})
        .catch(this.apiErrorHandler)
  },
  methods: {
    ...mapActions({
      getUser: "Api/Users/get",
      modifyUser: "Api/Users/modify",
      getFile: "Api/Files/get",
      logout: "Api/Users/logout"
    }),
    tryLogout() {
      this.logout({})
          .then(() => this.$router.push('/login'))
          .catch(this.apiErrorHandler)
    },
    onSubmit(file_id) {
      this.modifyUser({
        id: 'self',
        files: [{id: file_id}]
      })
          .then(console.log)
          .catch(this.apiErrorHandler)
    }
  },
}
</script>

<style scoped>

</style>
