<template>
  <div class="image-header" :style="{'background-image': 'url(\''+image+'\')'}">
    <div class="header-text-wrap">
      <div class="header-text">
        <slot/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ImageHeader",
  props: {
    image: {type: String, default: ''}
  }
}
</script>

<style scoped>

</style>
