<template>
  <div class="carousel-header">
    <vue-slick-carousel dots>
      <div v-for="item in items" class="carousel-item" :style="{'background-image': 'url(\''+item.image+'\')'}">
        <div class="container">
          <a :href="item.link" class="item-link">{{item.link_text}}</a>
        </div>
      </div>
    </vue-slick-carousel>
  </div>
</template>

<script>

import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  name: "CarouselHeader",
  components: {VueSlickCarousel},
  props: {
    items: {type: Array, default: () => []}
  }
}
</script>

<style scoped>

</style>
