<template>
  <div class="enroll pb-5 row px-3 overflow-auto justify-content-center">
    <div class="col-11 col-md-5 col-xl-4">
      <div v-if="pending" class="pending-overlay">
        <img src="/img/loading.svg" class="img-fluid sticky-top">
      </div>
      <h2 class="align-items-center pt-5 pt-md-3" v-t="'enroll.title'"></h2>
      <p class="mb-5" v-t="'enroll.body'"></p>

      <form method="post" @submit.prevent="tryEnroll">
        <template v-if="!enrollmentToken && !email">
          <label class="small" v-t="'Zugangscode:'"></label>
          <div class="input-group mb-3">
            <input type="text"
                   class="form-control"
                   :placeholder="$t('Code einlösen...')"
                   :disabled="enrollmentToken.length > 0"
                   v-model.trim="enrollToken"
            >
            <div v-if="qRScannerAvailable" class="input-group-append ">
              <button type="button"
                      class="btn btn-primary box-shadow"
                      @click="scanQRCode"
              >
                <i class="fas fa-qrcode"></i>
              </button>
            </div>
          </div>
        </template>
        <template v-else>
          <label class="small" v-t="'Email:'"></label>
          <input type="text"
                 class="form-control"
                 disabled
                 :value="decodeURIComponent(email)"
          >
        </template>


        <input class="box-shadow rounded pl-3 form-control mt-5"
               :placeholder="$t('Passwort')"
               type="password"
               v-model.trim="password"
        />
        <input class="box-shadow rounded pl-3 form-control mt-2"
               :placeholder="$t('Passwort bestätigen')"
               type="password"
               v-model.trim="password2"
        />
        <button class="btn btn-primary btn-block text-white box-shadow mt-4"
                v-t="'Registrierung abschließen'"
        ></button>
        <template v-if="showLoginLink">
          <hr class="my-5">
          <router-link class="btn btn-outline-light btn-block mb-4" to="/"
                       v-t="'Zurück zum Login'"/>

        </template>
      </form>
    </div>
  </div>
</template>


<script>

import LoginOptions from 'pixelstein-vue-app-package/src/mixins/login-options'
import {mapActions} from "vuex";

export default {
  name: "Enroll",
  mixins: [LoginOptions],
  props: {
    showLoginLink: {type: Boolean, default: false},
    successRedirect: {type: String, default: '/'},
    enrollmentToken: {type: String, default: ''},
    email: {type: String, default: ''}
  },
  data() {
    return {
      enrollToken: '',
      password: '',
      password2: '',
      pending: false,
    }
  },
  watch: {
    enrollmentToken(newValue) {
      this.enrollToken = newValue
    }
  },
  computed: {
    qRScannerAvailable() {
      return window.cordova
          && window.cordova.plugins
          && window.cordova.plugins.barcodeScanner
          && typeof window.cordova.plugins.barcodeScanner.scan === "function"
    }
  },
  mounted() {
    this.enrollToken = this.enrollmentToken ? this.enrollmentToken : '';

    if (window.StatusBar) {
      window.StatusBar.styleLightContent();
    }
  },
  methods: {
    ...mapActions({
      enroll: "Api/Users/confirmSignup"
    }),
    tryEnroll() {
      if (this.password !== this.password2) {
        return this.$toast.open({
          message: this.$t('Passwörter stimmen nicht überein!'),
          type: 'error',
          position: 'top'
        })
      }

      if (this.password.length < 8) {
        return this.$toast.open({
          message: this.$t('Dein Passwort muss mindestens 8 Zeichen lang sein!'),
          type: 'error',
          position: 'top'
        })
      }

      this.pending = true;
      this.enroll({
        token: this.enrollToken,
        password: this.password,
        identifier: this.getDeviceUuidOption(),
        platform: this.getDevicePlatformOption(),
        // push_token: this.pushToken,
        app_id: this.getBundleIdOption()
      })
          .then(() => {
            this.$router.push(this.successRedirect)
          })
          .catch(this.apiErrorHandler).finally(() => this.pending = false)
    },
    scanQRCode() {
      if (this.qRScannerAvailable) {
        window.cordova.plugins.barcodeScanner.scan(
            this.qrSuccessHandler,
            this.qrErrorHandler,
            {
              preferFrontCamera: true, // iOS and Android
              showFlipCameraButton: false, // iOS and Android
              showTorchButton: false, // iOS and Android
              torchOn: false, // Android, launch with the torch switched on (if available)
              saveHistory: false, // Android, save scan history (default false)
              prompt: "Place a barcode inside the scan area", // Android
              resultDisplayDuration: 500, // Android, display scanned text for X ms. 0 suppresses it entirely, default 1500
              formats: "QR_CODE", // default: all but PDF_417 and RSS_EXPANDED
              orientation: "portrait", // Android only (portrait|landscape), default unset so it rotates with the device
              disableAnimations: true, // iOS
              disableSuccessBeep: false // iOS and Android
            }
        );
      }
    },
    qrSuccessHandler(result) {
      if (result.cancelled) {
        return false;
      }
      // sample => livecoach://#/mobile/enroll/5c85ed11-6dc4-4eb3-b7c3-1315fad79e0e
      let prefix = this.$config.ENROLLMENT_TARGET_URL + '?';
      if (result.text.match(prefix)) {
        let query = result.text.split(prefix).pop();
        let params = {};
        query.split('&').forEach(el => {
          let keyValue = el.split('=');
          params[keyValue[0]] = keyValue[1];
        });
        this.enrollToken = params.enrollment
      } else {
        this.qrErrorHandler()
      }
    },
    qrErrorHandler() {
      this.$toast.open({
        message: this.$t('Fehler beim lesen des QR-Codes'),
        type: 'danger',
        position: 'top'
      })
    },
  }
}

</script>
