<template>
  <div class="home view">

    <carousel-header :items="region.public_config.home.carousel"/>

    <div v-if="loading" class="text-center my-5 py-5">
      <img src="/img/loading.svg" alt="loading">
    </div>
    <template v-else>
      <div class="container">
        <h1>{{ $t('home.modules') }}</h1>
        <div class="row">
          <div v-for="module in modules" :key="module.id" class="col-sm-6 col-lg-3 mb-3">
            <module-card :module="module" class="h-100"/>
          </div>
        </div>
      </div>
    </template>
    <div v-if="region.public_config.home.content_after_top_rated" class="container">
      <text-pic
          :title="region.public_config.home.content_after_top_rated.title"
          :body="region.public_config.home.content_after_top_rated.body"
          :image="region.public_config.home.content_after_top_rated.image"
      />
    </div>


    <div v-if="region.public_config.home.content_before_footer" class="container mb-5">
      <text-pic
          :title="region.public_config.home.content_before_footer.title"
          :body="region.public_config.home.content_before_footer.body"
          :image="region.public_config.home.content_before_footer.image"
      />
    </div>

  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";
import ModuleCard from "@/components/ModuleCard";
import TextPic from "@/components/TextPic";
import CarouselHeader from "@/components/CarouselHeader";

export default {
  name: "Home",
  components: {CarouselHeader, TextPic, ModuleCard},
  data() {
    return {
      loading: true
    }
  },
  computed: {
    ...mapState({
      region: state => state.Api.Regions.current,
      modules: state => state.Api.Modules.all,
    })
  },
  created() {
    this.clearModules();
    this.getModules({
      region_id: this.region.id,
      sort: "finished_count",
      order: "asc",
      limit: 4
    })
        .catch(this.apiErrorHandler)
        .finally(() => this.loading = false)
  },
  methods: {
    ...mapMutations({
      clearModules: "Api/Modules/reset",
    }),
    ...mapActions({
      getModules: "Api/Modules/all",
    })
  }
}
</script>
