<template>
	<div class="module-content view bg-secondary">
		<div v-if="!module" class="text-center my-5 py-5">
			<img src="/img/loading.svg" alt="loading">
		</div>
		<div v-else class="container py-1">
			<h1 class="text-white">{{ module.name }}</h1>
			<div v-if="moduleFailed" class="row py-5 ">
				<div class="col-12 text-center py-5 my-5">
					<h2 class="mb-5 text-white">{{ $t('module_content.module_failed.title') }}</h2>
					<router-link :to="r('/modules','view',module_id)" replace class="btn btn-primary px-5">
						{{ $t('module_content.module_failed.button_text') }}
					</router-link>
				</div>
			</div>
			<div v-else-if="currentStepContent && currentStepContent.type === 'presentation'" class="row">
        <div class="col-12 text-right mb-3">
          <button type="button"
                  class="btn btn-primary"
                  :disabled="nextStepLock"
                  @click="nextStep">
            <template v-if="nextStepLock">{{ $t('module_content.countdown', { minutes: this.countdown }) }}</template>
            <template v-else>{{ $t('module_content.next') }}</template>
          </button>
          <p class="small text-white" v-if="nextStepLock">{{ $t('module_content.wait') }}</p>
        </div>
				<div class="col-md-8">
					<media-player :files="currentStepContent.files" :start-file-idx="file_idx" :next-step-lock="nextStepLock" :countdown="this.countdown" @nextStep="nextStep"/>
				</div>
				<div class="col-md-4 text-white">
					<div v-html="module.body"></div>
					<button type="button"
							class="btn btn-primary"
							:disabled="favouriteLock"
							@click="toggleFavorite"
					>
						{{
                        !favourite
                            ? $t('module_content.add_favorite')
                            : $t('module_content.remove_favorite')
						}}
					</button>
				</div>
        <div class="col-md-8">
          <p class="small text-white">{{ $t('module_content.hint') }}</p>
        </div>
				<div class="col-12 text-right mt-3">
					<button type="button"
							class="btn btn-primary"
							:disabled="nextStepLock"
							@click="nextStep">
						<template v-if="nextStepLock">{{ $t('module_content.countdown', { minutes: this.countdown }) }}</template>
						<template v-else>{{ $t('module_content.next') }}</template>
					</button>
					<p class="small text-white" v-if="nextStepLock">{{ $t('module_content.wait') }}</p>
				</div>
			</div>
			<div v-else-if="currentStepContent && currentStepContent.type === 'quiz'" class="row">
				<div class="col-12">
					<quiz-content
							:questions="currentStepContent.questions"
							@answer="onAnswer"
							@done="nextStep"
					/>
				</div>
			</div>
			<div v-else class="text-center py-5">
				<img src="/img/loading.svg" class="my-5" alt="loading">
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import MediaPlayer from '@/components/MediaPlayer'
import QuizContent from '@/components/QuizContent'
import dayjs from 'dayjs'

export default {
  name: 'ModuleContent',
  components: { QuizContent, MediaPlayer },
  props: {
    module_id: { type: String, default: null },
    step_id: { type: String, default: null },
    step_content_id: { type: String, default: null },
    file_idx: { type: Number, default: 0 },
  },
  watch: {
    module_id (nv) {
      if (!this.module || this.module.id !== nv) {
        this.fetchModule()
      }
    },
    step_id (nv, ov) {
      if (nv && nv !== ov) {
        this.fetchModuleStep()
      }
    },
    currentStepContentDuration (nv) {
      this.lockStep(nv)
    },
  },
  data () {
    return {
      moduleFailed: false,
      favouriteLock: false,
      nextStepLock: false,
      countdown: '',
      end: null,
    }
  },
  computed: {
    ...mapState({
      module: state => state.Api.Modules.current,
      favourites: state => state.Api.Favourites.all,
      moduleStep: state => state.Api.ModuleSteps.current,
    }),
    favourite () {
      return this.favourites.find(el => el.module_id === this.module_id)
    },
    currentStepIndex () {
      if (!this.module) {
        return null
      }

      return this.module.module_steps.findIndex(el => el.id === this.step_id)
    },
    currentStepContentIndex () {
      if (!this.moduleStep) {
        return null
      }

      return this.moduleStep.step_contents.findIndex(el => el.id === this.step_content_id)
    },
    currentStepContent () {
      if (this.currentStepContentIndex === null
        && this.currentStepContentIndex >= 0) {
        return null
      }

      return this.moduleStep.step_contents[this.currentStepContentIndex]
    },
    currentStepContentDuration () {
      if (!this.currentStepContent) {
        return 0
      }

      const durationSummary = this.currentStepContent.files
        .filter(el => el.duration)
        .reduce((previousValue, currentValue) => previousValue + currentValue.duration, 0) * 1000

      return Math.max((durationSummary / 2), (this.$config.MIN_MODULE_DURATION_IN_SEC * 1000))
    },
    nextStepContentId () {
      if (this.currentStepContentIndex + 1 === this.moduleStep.step_contents.length) {
        return null
      }

      return this.moduleStep.step_contents[this.currentStepContentIndex + 1].id

    },
    nextStepId () {
      if (this.currentStepIndex + 1 === this.module.module_steps.length) {
        return null
      }

      return this.module.module_steps[this.currentStepIndex + 1].id
    },
    /** @deprecated */
    currentStepContentFile () {
      if (!this.currentStepContent) {
        return null
      }

      return this.currentStepContent.files[this.file_idx]
    },
  },
  created () {
    if (!this.module || this.module.id !== this.module_id) {
      this.fetchModule()
    }

    this.fetchModuleStep()
      .then(() => this.lockStep(this.currentStepContentDuration))
  },
  methods: {
    ...mapMutations({
      setModule: 'Api/Modules/setCurrent',
    }),
    ...mapActions({
      getModule: 'Api/Modules/get',
      getStep: 'Api/ModuleSteps/get',
      answerQuestion: 'Api/Questions/answer',
      completeModuleStep: 'Api/Modules/completeStep',
      completeModule: 'Api/Modules/complete',
      addFavourite: 'Api/Favourites/add',
      removeFavourite: 'Api/Favourites/delete',
      getFavourites: 'Api/Favourites/all',
    }),
    toggleFavorite () {
      if (this.favouriteLock) {
        return
      }
      this.favouriteLock = true

      let promise = null

      if (this.favourite) {
        promise = this.removeFavourite(this.favourite)
      } else {
        promise = this.addFavourite({
          user_id: 'self',
          module_id: this.module_id,
        })
      }

      promise
        .catch(this.apiErrorHandler)
        .finally(() => this.favouriteLock = false)

    },
    async onAnswer (options) {
      if (this.answerLock) {
        return
      }

      this.answerLock = true

      try {
        const result = await this.answerQuestion(options)

        if (result.failed_module) {
          this.$router.replace(this.r('/modules', 'failed', this.module_id)).catch(console.log)
        } else {
          options.onSuccess()
        }

      } catch (e) {
        this.apiErrorHandler(e)
      }

      this.answerLock = false
      if (typeof options.onFinally === 'function') {
        options.onFinally()
      }

    },
    onQuizComplete (options) {
      console.log(options)
      if (this.module.sudden_death) {
        //next step
      }
    },
    async nextStep () {
      if (this.nextStepLock === true) {
        return
      }
      this.nextStepLock = true

      try {
        if (this.nextStepContentId) {

          this.$router.push(this.r('/modules', 'view', this.module_id, this.step_id, this.nextStepContentId))
            .catch(console.log)

        } else if (this.nextStepId) {

          await this.completeModuleStep({ id: this.module_id, index: this.currentStepIndex })

          this.$router.push(this.r('/modules', 'view', this.module_id, this.nextStepId)).catch(console.log)

        } else {

          await this.completeModuleStep({ id: this.module_id, index: this.currentStepIndex })
          const cm = await this.completeModule({ id: this.module_id })
          console.log('cm', cm)
          this.$router.replace(this.r('/modules', 'finished', this.module_id)).catch(console.log)

        }
      } catch (e) {
        this.apiErrorHandler(e)
      } finally {
        this.unlockStep()
      }
    },
    fetchModule () {
      this.setModule({ item: null })
      return this.getModule({ id: this.module_id })
        .catch(this.apiErrorHandler)
    },
    fetchModuleStep () {
      return this.getStep({ id: this.step_id })
        .then(step => {
          if (this.step_content_id === null && step.step_contents.length > 0) {
            this.$router.replace(this.r('/modules', 'view', this.module_id, this.step_id, step.step_contents[0].id))
          }
        })
        .catch(this.apiErrorHandler)
    },
    lockStep (duration) {
      this.unlockStep()

      if (this.currentStepContent?.type !== 'presentation') {
        return
      }

      this.nextStepLock = true
      this.end = (+new Date()) + duration
      this.nextStepTimer = window.setTimeout(() => this.unlockStep(), duration)
      this.nextStepInterval = window.setInterval(() => this.updateCountdown(), 1000)
    },
    updateCountdown () {
      const now = dayjs()
      this.countdown = dayjs(this.end).diff(now, 'm')
    },
    unlockStep () {
      if (this.nextStepTimer) {
        window.clearTimeout(this.nextStepTimer)
      }

      if (this.nextStepInterval) {
        window.clearInterval(this.nextStepInterval)
      }

      this.countdown = ''
      this.end = null
      this.nextStepLock = false
    },
  },
}
</script>
