import {mapActions, mapMutations, mapState} from "vuex";
import dayjs from "dayjs";
import "dayjs/locale/de"

export default {
	computed: {
		...mapState({
			region: state => state.Api.Regions.current
		})
	},
	created() {
		if (!this.region) {
			this.setRegion({item: null})
			this.getRegion({domain: window.location.hostname})
				.then(region => {
					this.setRegion({item: region});
					this.$i18n.locale = region.language;
					dayjs.locale(region.language);
				})
				.catch(this.apiErrorHandler)
		}
	},
	methods: {
		...mapMutations({
			setRegion: "Api/Regions/setCurrent"
		}),
		...mapActions({
			getRegion: "Api/Regions/forDomain"
		})
	}
}
