<template>
  <general-card class="module-card" :image="moduleThumbnail">
    <p class="font-weight-bold">{{ module.name }}</p>
    <p class="lessons">{{ $t('module_card.lessons', {count: module.module_steps_count}) }}</p>
    <p v-if="showSummary" class="card-text" v-html="module.body"></p>
    <div class="text-center mt-4 mb-1">
      <router-link :to="r('/modules','view', module.id)" class="btn btn-primary">
        {{ $t('module_card.start') }}
      </router-link>
    </div>
  </general-card>
</template>

<script>
import GeneralCard from "@/components/GeneralCard";

export default {
  name: "ModuleCard",
  components: {GeneralCard},
  props: {
    module: {type: Object, default: () => ({files: []})},
    showSummary: {type: Boolean, default: false}
  },
  computed: {
    moduleThumbnail() {
      if (this.module.files.length === 0) {
        return null
      }

      return this.r(this.$config.API_BASE_URL, this.module.files[0].thumbnail)
    }
  }
}
</script>

<style scoped>

</style>
