<template>
  <div class="text-pic">
    <h1 v-if="title">{{ title }}</h1>
    <div class="row" :class="{'flex-column-reverse': reverse}">
      <div v-if="body" class="col" v-html="body"></div>
      <div v-if="image" class="col-md-4">
        <img :src="image"
             :alt="image"
             class="img-fluid">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TextPic",
  props: {
    title: {type: String, default: null},
    body: {type: String, default: null},
    image: {type: String, default: null},
    reverse: {type: Boolean, default: false}
  }
}
</script>

<style scoped>

</style>
