<template>
  <div class="module-content-preview">
    <template v-for="step in contents">
      <div v-for="stepContent in step.step_contents" class="step">
        <div v-if="stepContent.type === 'presentation'" class="content">
          <div v-for="(file, idx) in stepContent.files"
               class="file"
               @click="$emit('click', {step: step.id, stepContent: stepContent.id, fileIdx: idx})">
            <i class="far fa-play-circle"></i>
            {{ idx + 1 }}. {{ file.title || file.filename }}
            <span class="duration">{{ toPlayTime(file.duration) || '-' }}</span>
          </div>
        </div>
        <div v-if="stepContent.type === 'quiz'" class="content py-3 pl-5 ml-2">
          {{ $t(stepContent.type) }} - {{ stepContent.sudden_death ? $t('module_content_preview.exam') : '' }}
          {{
            $tc('module_content_preview.questions', stepContent.questions_count, {count: stepContent.questions_count})
          }}
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "ModuleContentPreview",
  props: {
    contents: {type: Array, default: () => []}
  },
  methods: {
    toPlayTime(seconds) {
      return dayjs().startOf('day').second(seconds).format(seconds > 3600 ? 'HH:mm:ss' : 'mm:ss')
    }
  }
}
</script>

<style scoped>

</style>
