<template>
  <div class="quiz-content" v-if="currentQuestion">
    <div class="question">
      <h3 v-html="[findQuestionIndex(currentQuestion) + 1,currentQuestion.body].join('. ')"
          class="mt-md-2 d-inline-block text-white"></h3>
    </div>
    <div class="answers" :class="{error}">
      <div v-for="(answer, answerIdx) in currentQuestion.answers"
           class="answer"
           :class="{correct: readonly && answer.correct, answered: readonly && answer.isMyAnswer}">
        <input :id="'A_'+answer.id"
               class="invisible position-absolute"
               :type="currentQuestion.type === 'single'?'radio':'checkbox'"
               :value="answer.id"
               :name="'Q_'+currentQuestion.id"
               :disabled="readonly"
               v-model="currentAnswer[answer.id]"
        >
        <label :for="'A_'+answer.id" v-html="answer.body"></label>
      </div>
    </div>
    <div class="pagination">
      <template v-if="questions.length > 0 && !readonly">
        <button type="button" class="btn"><i class="fas fa-chevron-left fa-2x" aria-hidden="true"></i></button>
        <button v-for="question in questions"
                :key="question.id"
                type="button"
                class="btn btn-sm"
                :class="{answered: isQuestionAnswered(question)}">●
        </button>
        <button type="button" class="btn"><i class="fas fa-chevron-right fa-2x" aria-hidden="true"></i></button>
      </template>
    </div>
    <div class="button-wrap">
      <button v-if="readonly" type="button" :disabled="buttonDisabled" @click="showNext">
        {{ questions.length - 1 === answeredQuestions.length ? $t('finish') : $t('next') }}
      </button>
      <button v-else type="button" :disabled="buttonDisabled" @click="answer">
        <img v-if="pending" src="/img/loading.svg" class="img-fluid" alt="loading">
        <template v-else>
          {{ $t('Antwort abgeben') }}
        </template>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "QuizContent",
  props: {
    questions: {type: Array, default: () => []},
    previouslyAnsweredQuestions: {type: Array, default: () => []},
    readonly: {type: Boolean, default: false}
  },
  data() {
    return {
      currentAnswer: {},
      answeredQuestions: [],
      error: false,
      pending: false
    }
  },
  computed: {
    buttonDisabled() {
      if (this.pending) {
        return true
      }

      if (this.readonly) {
        return false
      }
      return Object.values(this.currentAnswer).filter(el => el).length === 0
    },
    currentQuestion() {
      return this.questions.find(question => {
        return ![...this.answeredQuestions, ...this.previouslyAnsweredQuestions].find(correct => {
          if (correct.question_id) {
            return correct.question_id === question.id
          }
          return correct.id === question.id
        })
      })
    }
  },
  methods: {
    answer() {
      if (this.pending === true) {
        return
      }

      this.pending = true;

      const onSuccess = () => {
        this.error = false;
        this.answeredQuestions.push(this.currentQuestion)
        this.currentAnswer = {};
        if (!this.currentQuestion) {
          this.$emit('done', this.answeredQuestions)
        }
      }
      const onError = () => {
        this.error = true;
      }
      const onFinally = () => {
        this.pending = false
      }

      let answers = Object.keys(this.currentAnswer);
      answers = answers.filter(id => this.currentAnswer[id]).map(aid => ({id: aid}))


      let data = {
        id: this.currentQuestion.id,
        answers,
        onSuccess,
        onError,
        onFinally
      }

      this.$emit('answer', data);
    },
    findQuestionIndex(question) {
      return this.questions.findIndex(q => q.id === question.id)
    },
    isQuestionAnswered(question) {
      return !!this.answeredQuestions.find(q => q.id === question.id)
    },
    showNext() {
      this.answeredQuestions.push(this.currentQuestion)
      if (this.answeredQuestions.length === this.questions.length) {
        this.$emit('done')
      }
    }
  }
}
</script>
